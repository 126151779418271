@keyframes popup {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes shimmer {
  0% {
    opacity: 0;
    left: -50%;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 100%;
  }
}
.logout-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: #fffa;
}
.page-loading {
  padding: 1rem 40px;
  > span {
    position: relative;
    display: block;
    width: 50%;
    background-color: #eee;
    height: 60px;
    overflow: hidden;
    &::before {
      content: '';
      animation: shimmer 1s infinite ease-in;
      position: absolute;
      height: 250%;
      left: 0;
      top: 0;
      box-shadow: 0 0 10px #fff;
      width: 2rem;
      background-color: #fff5;
      transform: rotate(30deg) translateY(-50%);
    }
    &:nth-child(2) {
      height: 300px;
      margin-top: 1rem;
      width: 100%;
    }
  }
}
.admin-table {
  margin-bottom: 2rem;
  .ant-table {
    &-container {
      border: 1px solid #0001;
      border-top: none;
      border-bottom: none;
    }
  }
  .ant-pagination {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 0.8rem 0;
    border-top: 1px solid #eee;
    margin-top: 0;
  }
}
.page-top {
  position: sticky;
  top: 0;
  background-color: #fffe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  z-index: 100;
  // margin-bottom: -1px;
  height: 80px;
  &-title {
    display: flex;
    align-items: center;
    font-weight: bold;
    > span {
      margin-right: 0.8rem;
      svg {
        // border: 2px solid currentColor;
        background-color: #001529;
        color: #fff;
        padding: 0.5rem;
        box-sizing: content-box;
        border-radius: 50%;
        overflow: visible;
        font-size: 120%;
        // box-shadow: 0 2px 5px #0002;
      }
    }
    > p {
      animation: popup 0.2s;
      margin: 0;
      font-size: 150%;
      > span {
        display: block;
        line-height: 1;
        font-size: 0.9rem;
        font-weight: 400;
      }
    }
  }
  &-right {
    display: flex;
    align-items: center;
  }
}
/* LOADING SPINNER */
.loading {
  &-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
}

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-image: url(../../public/カギベース案③20221014.jpg);
  background-position: center;
  img {
    width: 30px;
    margin-bottom: 1rem;
  }
  &-form {
    text-align: center;
  }
  &-container {
    background-color: #fffe;
    padding: 2rem;
    border-radius: 0.5rem;
  }
}

.kagi-modal {
  p {
    margin: 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .user-add {
    margin-bottom: 1rem;
  }
  .user-item {
    animation: popup 0.2s;
    position: relative;
    border: 1px solid #0003;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    &--loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fffa;
      z-index: 20;
    }
    &-head {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0.7rem;
      padding-right: 0;
      border-bottom: 1px solid #eee;
      box-sizing: border-box;
      &__title {
        flex: 1;
        display: flex;
        > span {
          svg {
            margin-right: 0.7rem;
            // border: 1px solid #0002;
            padding: 0.5rem;
            box-sizing: content-box;
            border-radius: 50%;
            overflow: visible;
            background-color: #2196ff12;
            color: #2196ff;
          }
        }
        > p {
          word-wrap: break-word;
          word-break: break-all;
          h4 {
            margin: 0;
            line-height: 1.2;
            word-wrap: break-word;
          }
          > span {
            font-size: 0.7rem;
            // font-weight: 500;
            // color: #999;
          }
        }
      }
    }
    &-form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem;
      padding: 0.7rem;
      // background-color: #fafafa;
      > p {
        font-weight: bold;
      }
      > span {
        label {
          display: block;
          font-size: 0.7rem;
          margin-bottom: 3px;
        }
      }
    }
    &-bottom {
      margin-top: 1rem;
      text-align: right;
      grid-area: auto / auto / auto / span 2;
    }
    // &:first-child {
    //   border-radius: 5px 5px 0 0;
    // }
    // &:last-child {
    //   border-radius: 0 0 5px 5px;
    // }
    &:not(:first-child) {
      margin-top: 0.5rem;
      // border-top: none;
    }
  }
}
.b-mode {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  // font-weight: 600;
  svg {
    margin-right: 5px;
  }
  &--normal {
    background-color: #6f02;
    color: green;
  }
  &--booking {
    background-color: #2196ff12;
    color: #2196ff;
  }
}
.limit-picker{
  .rw-widget-picker{
    &:hover{
      border-color: #2e93f1
    }
    &:focus{
      box-shadow: unset;
    }
    min-height: 32px;
    border-radius: unset;
    border: 1px solid #d9d9d9;
    .rw-widget-input{
      box-shadow: unset;
    }
    .rw-input-addon.rw-picker-btn{
      border: unset;
      span{
        font-size: 12px;
        margin-right: 4px;
        color: rgba(0, 0, 0, .25);
      }
    }
  }
}
