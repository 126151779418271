.t-c {
  text-align: center;
}

.d-f {
  display: flex;
}

.fd-c {
  flex-direction: column;
}
